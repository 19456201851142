import "./css/Services.css";

const Membership = () => {
    return (
        <div className="specificService">
            <div className="container py-5">
                <div className="text-center mb-4">
                    <h2 className="fw-semibold mt-3">Join Our Membership</h2>
                    <p className="text-muted">
                        Be part of a community dedicated to advancing gender equality and supporting persons with disabilities in Kenya.
                    </p>
                </div>

                {/* Membership Benefits */}
                <div className="row mb-5">
                    <div className="col-lg-6 mb-3">
                        <h4 className="fw-semibold text-dark">Benefits of Membership</h4>
                        <ul className="text-muted">
                            <li>Access to exclusive resources and training materials</li>
                            <li>Invitations to workshops, seminars, and events</li>
                            <li>Networking opportunities with professionals and advocates</li>
                            <li>Opportunities for community involvement and volunteering</li>
                            <li>Regular updates on our programs and initiatives</li>
                        </ul>
                    </div>

                    {/* Membership Requirements */}
                    <div className="col-lg-6">
                        <h4 className="fw-semibold text-dark">Membership Requirements</h4>
                        <ul className="text-muted">
                            <li>Commitment to GDDC's mission and values</li>
                            <li>Participation in at least one event per year</li>
                            <li>Active involvement in GDDC Kenya’s activities</li>
                            <li>Nominal annual membership fee</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Membership;
