import "./css/style.css";

const AwardsSelectionCriterion = () => {
  return (
    <div className="container">
      <h3 className="text-center mb-4">Awards Selection Criterion</h3>
      <div className="row">
        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">Inclusive Education Awards</h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>
                Basic Education - The Most Inclusive Learning Institution:
              </strong>
              Accessibility, SNE Curriculum Implementation, Teachers Pedagogy,
              KNEC Results
              <br />
              <em>Key Awards Informants:</em> MoE, KNEC, KISE, GDDC, Independent
              Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Tertiary - The Most Inclusive TVET Institution:</strong>
              Accessibility, Enrolment, Tutor Pedagogy, Etiquette,
              Levels/Culture, KNEC Results, Employability Output Levels
              <br />
              <em>Key Awards Informants:</em> MoE, KATTI, KNEC, KISE, GDDC,
              Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>University - Most Inclusive University in Kenya:</strong>
              Accessibility, Admissions, Lecturers Pedagogy, Etiquette,
              Levels/Culture, Employability Output Levels
              <br />
              <em>Key Awards Informants:</em> MoE, CUE, KNEC, KISE, GDDC,
              Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Professional Bodies and Associations:</strong>
              Number of Members with Disabilities, Inclusive Membership Policy,
              Demonstrated Mainstreaming Programs
              <br />
              <em>Key Awards Informants:</em> MoE, KNQA, KENAS, MOLSP, GDDC,
              Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>
                County Education Stimuli - Most Inclusive County in Provision of
                Inclusive Education:
              </strong>
              Robust Affirmative Action Policy, County Disability Policy,
              Actualized Implementation Plans
              <br />
              <em>Key Awards Informants:</em> MoE, CoG, KNQA, KENAS, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Most Voted Education Assessment Resource Centre:</strong>
              Peer Recommendation for Excellence in Inclusive Education
              Resources
              <br />
              <em>Key Awards Informants:</em> MoE, CoG, GDDC, Independent Expert
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">Inclusive Health Awards</h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Private Hospitals:</strong> Accessibility, Customer
              Support, Etiquette, Inclusion Policy
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>The Most Inclusive Private Hospitals:</strong>
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Government-Owned Hospitals (Devolved):</strong>{" "}
              Accessibility, Inclusive Service Provision, Etiquette
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Most Inclusive Level Category Hospital:</strong> (KNH,
              MTRH, Level 5, 4, 3 Hospitals)
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Referral Hospitals:</strong> Accessibility, Inclusive
              Service Provision, Etiquette
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Medical Training Institutions:</strong> Accessibility,
              Inclusive Service Provision, Etiquette, Curriculum, and Pedagogy
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Mental Health Service Provision:</strong> Facilities and
              Inclusive Manpower
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>The Most Inclusive Rehabilitation Centre:</strong>
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>County Inclusive Health Policy and Programs:</strong>{" "}
              Recognizing the Most Inclusive Counties in Kenya (Winners, 1st and
              2nd Runners-up)
              <br />
              <em>Key Awards Informants:</em> MoH, CoG, KMPDU, NNA, NCK, MOLSP,
              GDDC, Independent Expert
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">Legal Inclusiveness Awards</h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Young Professionals:</strong> Recognizes inclusive legal
              services provided by young professionals in law firms, legal
              support, and pro-bono services.
              <br />
              <em>Key Awards Informants:</em> LSK, JSC, NGEC, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>
                The Most Inclusive High Courts and Resident Magistrate Courts:
              </strong>{" "}
              Acknowledges inclusivity efforts in high courts across Kenya.
              <br />
              <em>Key Awards Informants:</em> JSC, NCPWD, GDDC
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Non-Profit/Government:</strong> Recognizes inclusivity in
              INGOs and NGOs that contribute to legal support and empowerment.
              <br />
              <em>Key Awards Informants:</em> GDDC
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Law Firms:</strong> Highlights inclusive legal practices
              and public engagement by law firms.
              <br />
              <em>Key Awards Informants:</em> LSK, GDDC, Public, MEDIA
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Individuals:</strong> Celebrates individual contributions
              to public legal inclusiveness.
              <br />
              <em>Key Awards Informants:</em> LSK, GDDC, Public, MEDIA
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">
            Data, Technology, and Innovation Awards
          </h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Most Accessible Websites and Portals:</strong>
              <br />
              <em>Key Awards Informants:</em> The Public, GDDC, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Inclusive ICT Solution:</strong>
              <br />
              <em>Key Awards Informants:</em> The Public, GDDC, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Inclusion Innovation of the Year:</strong>
              <br />
              <em>Key Awards Informants:</em> The Public, GDDC, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Inclusion Technology Champion of the Year:</strong>
              <br />
              <em>Key Awards Informants:</em> GDDC, The Public, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Winner (Non-State):</strong>
              <br />
              <em>Key Awards Informants:</em> GDDC, The Public, ICTA, In-Able,
              KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Winner (GoK):</strong>
              <br />
              <em>Key Awards Informants:</em> ICTA, In-Able, GDDC, KEBS, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Overall Winner:</strong>
              <br />
              <em>Key Awards Informants:</em> ICTA, In-Able, GDDC, KEBS, NCPWD
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">Economic Empowerment Awards</h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Most Inclusive Micro and Small Enterprise:</strong>
              <br />
              <em>Key Awards Informants:</em> GDDC, Ministry of Cooperatives and
              MSMEs, MSEA, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Micro-Entrepreneur with Disability:</strong>
              <br />
              <em>Key Awards Informants:</em> GDDC, Ministry of Cooperatives and
              MSMEs, NCPWD
            </li>
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Micro-Entrepreneur with Disability (AGPO):</strong>
              <br />
              <em>Key Awards Informants:</em> GDDC, Ministry of Cooperatives and
              MSMEs, MSEA, NCPWD
            </li>
          </ul>
        </div>

        <div className="col-md-6 mb-4">
          <h5 className="text-secondary">
            Climate Change and Disability Inclusion Awards
          </h5>
          <ul className="list-unstyled">
            <li className="mb-3">
              <i className="fas fa-check text-secondary me-2"></i>
              <strong>Inclusive Climate Interventions:</strong> Recognizing
              counties with inclusive climate policies, <br />
              <em>Key Awards Informants:</em> Kenya Forest Service(KFS), Kenya
              Forest Research Institute(KEFRI), Kenya Water Towers Agency,
              NETFUND, NECC, National Environmental Tribunal, GDDC.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AwardsSelectionCriterion;
