import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function DynamicTitle() {
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case "/":
                document.title = "Home | Gender Disability and Development Centre";
                break;
            case "/about":
                document.title = "About | Gender Disability and Development Centre";
                break;
            case "/services":
                document.title = "Services | Gender Disability and Development Centre";
                break;
            case "/services/research-and-publications":
                document.title = "Research and Publications | Gender Disability and Development Centre";
                break;
            case "/services/training-and-workshops":
                document.title = "Training and Workshops | Gender Disability and Development Centre";
                break;
            case "/services/certification":
                document.title = "Certification | Gender Disability and Development Centre";
                break;
            case "/services/policy-development":
                document.title = "Policy Development | Gender Disability and Development Centre";
                break;
            case "/services/consultancy":
                document.title = "Consultancy | Gender Disability and Development Centre";
                break;
            case "/events":
                document.title = "Events | Gender Disability and Development Centre";
                break;
            case "/membership":
                document.title = "Membership | Gender Disability and Development Centre";
                break;
            case "/contact":
                document.title = "Contact | Gender Disability and Development Centre";
                break;
            case "/terms-and-conditions":
                document.title = "Terms and Conditions | Gender Disability and Development Centre";
                break;
            case "/privacy-policy":
                document.title = "Privacy Policy | Gender Disability and Development Centre";
                break;
            case "/awards":
                document.title = "Awards | Gender Disability and Development Centre";
                break;
            case "/resources":
                document.title = "Resources | Gender Disability and Development Centre";
                break;
            case "/national-equality-and-inclusion-awards":
                document.title = "National Equality and Inclusion Awards | Gender Disability and Development Centre";
                break;
            default:
                document.title = "Gender Disability and Development Centre";
        }
    }, [location]);

    return null;
}
