import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ id, title, description, image }) => {
  return (
    <div className="col-md-4 mb-4">
      <div className="card h-100 d-flex flex-column">
        <img
          src={image}
          alt={title}
          className="card-img-top"
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">{id}</h5>
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
          <Link
            to={`/services/${title.toLowerCase().replace(/\s+/g, "-")}`}
            className="btn btn-secondary text-light mt-auto"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
