import Navbar from "./Navbar"
import Footer from "./Footer"
import EqualityInclusionAwards from "./equalityawards/EqualityInclusionAwards"
import AwardAimsObjectives from "./equalityawards/AwardAimsObjectives"
import AwardsSelectionCriterion from "./equalityawards/AwardsSelectionCriterion"
import AwardsParticipants from "./equalityawards/AwardParticipants"
import Sponsorship from "./equalityawards/Sponsorship"
import SponsorCategories from "./equalityawards/SponsorCategories"
import ResponseDetails from "./equalityawards/ResponseDetails"

function NationalEqualityInclusionAwards() {
  return (
      <>
          <Navbar />
          <EqualityInclusionAwards />
          <AwardAimsObjectives />
          <AwardsSelectionCriterion />
          <AwardsParticipants />
          <Sponsorship />
          <SponsorCategories />
          <ResponseDetails />
          <Footer />
      </>
  )
}

export default NationalEqualityInclusionAwards
