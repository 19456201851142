import React from "react";
import "./css/style.css";
import Countdown from "react-countdown";

const AwardAimsObjectives = () => {
  const targetDate = new Date("2024-12-20T00:00:00");

  return (
    <div className="container">
      <div className="card my-5">
        <div className="card-title">
          <h3 className="text-center">
            Aims and Objectives of the Award Ceremony
          </h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <h5 className="card-title">Objectives</h5>
              <ul className="list-unstyled">
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Showcase Kenya’s successful Diversity, Equality, and Inclusion
                  programs across sectors.
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Promote the role of Social Inclusion in Kenya’s economic,
                  social, and political development.
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Present case studies and research findings on Diversity and
                  Inclusion.
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Bring together governments, development institutions,
                  investors, academics, and private sector institutions to
                  celebrate the Equality and Inclusion Indicator.
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Unite Equality and Inclusion policy developers, enforcers, and
                  implementers “under one roof.”
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <h5 className="card-title">Aligned Thematic Areas</h5>
              <ul className="list-unstyled mb-3">
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Inclusive Education and Training
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Inclusive Health
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Legislative Framework
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Inclusive Data, Technology, and Innovation
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Economic Empowerment
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Inclusive Climate Change Interventions
                </li>
                <li>
                  <i className="fas fa-check text-secondary me-2"></i>
                  Disability Inclusion Performance Indicator in MDAs
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="countdown-container text-center my-5">
        <h3 className="mb-4">Countdown to Award Ceremony</h3>
        <Countdown date={targetDate} renderer={CountdownRenderer} />
      </div>
    </div>
  );
};

const CountdownRenderer = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="row justify-content-center">
      <div className="col-6 col-md-2">
        <div className="countdown-box bg-danger text-white p-3 mx-2">
          <h2>{days}</h2>
          <p>Days</p>
        </div>
      </div>
      <div className="col-6 col-md-2">
        <div className="countdown-box bg-danger text-white p-3 mx-2">
          <h2>{hours}</h2>
          <p>Hours</p>
        </div>
      </div>
      <div className="col-6 col-md-2">
        <div className="countdown-box bg-danger text-white p-3 mx-2">
          <h2>{minutes}</h2>
          <p>Minutes</p>
        </div>
      </div>
      <div className="col-6 col-md-2">
        <div className="countdown-box bg-danger text-white p-3 mx-2">
          <h2>{seconds}</h2>
          <p>Seconds</p>
        </div>
      </div>
    </div>
  );
};

export default AwardAimsObjectives;
