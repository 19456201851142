import React from 'react';
import ServiceCard from './ServiceCard';
import research from "./images/research.jpg";
import training from "./images/training.png";
import policyDevelopment from "./images/H.E Hon ongwae during the 2015 awards.jpg";
import consultancy from "./images/img026.png";
import image from "./images/Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia.jpg";

const services = [
  {
    id: '01',
    title: 'Research and Publications',
    description: 'Through Disability Surveys and Gender Analysis Evaluation Tool Kits, the Centre has formulated Gender and Disability Reports, Disability Accessibility Audits setting the benchmark for the implementation of Gender and Disability Mainstreaming Actions in Governments, State, Public and Private Entities in Kenya.',
    image: research,
  },
  {
    id: '02',
    title: 'Training and Workshops',
    description: 'GDDC organizes quarterly and bi-annual in-housetraining workshops and seminars as a joint venture training initiative with MDAs, counties, universities, TVET institutions, INGOs, local NGOs, CBOs, Civil Society Institutions and the Private sector targeting employees and staff members of organizations, public and private institutions that endeavor to achieve policy reform and implement Gender and Disability Actions in the work environment.',
    image: training,
  },
  {
    id: '03',
    title: 'Certification',
    description: 'GDDC has accredited corporates/organizations and Gender and Disability professionals as accredited members. All accredited members receive discounted training certification programs for their Policy Developers, Board Representatives, Chief Executive Officers, Managing Directors, Departmental Heads/Supervisor, Gender and Disability Officers, Human Resource Development and Education institutions are now required to demonstrate Gender Awareness and Disability Accessibility best practices in the work place, through setting Gender Mainstreaming targets, policy reform, Budgetary Allocations towards Gender and Disability Mainstreaming  under MDA’S.  ',
    image: image,
  },
  {
    id: '04',
    title: 'Policy Development',
    description: 'As a thought leader in Gender and Disability Mainstreaming, GDDC hosts bi-annual policy analysis and development conferences/seminars providing relevant decision makers, stakeholders and government managers with ideas on the latest reforms, mandates and action plans in Gender Mainstreaming in Disability Policy in all levels of the organization.',
    image: policyDevelopment,
  },
  {
    id: '05',
    title: 'Consultancy ',
    description: 'As a Consultancy, GDDC also offers Gender and Disability Mainstreaming baseline surveys, situational analysis and policy planning actions to interested State/Government and Private Institutions. The evaluation framework employed by GDDC team of experts is drawn from a comprehensive and empirical evaluation framework of sex disaggregated data and disability audits obtained from Government and Private Firms who seek to achieve radical transformations in Gender and Disability Mainstreaming within programs and among their workforce. ',
    image: consultancy,
  },
];

const Services = () => {
  return (
    <div className="container py-5">
      <h2 className="mb-5 display-6 fw-medium text-center">Our Services</h2>
      <div className="row">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            id={service.id}
            title={service.title}
            description={service.description}
            image={service.image}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
