import { useState, useEffect } from "react";
import ReactTypingEffect from "react-typing-effect";
import slider1 from "./images/Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia.jpg";
import slider2 from "./images/Senior Staff Members represented GDDC at the County Peer Review Mechanism Steering Committee and Stakeholders workshop at Sewela.jpg";
import slider3 from "./images/PHOTO-2024-02-23-14-31-27.jpg";
import "./css/HeroCarousel.css";

const HeroCarousel = () => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCarouselIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-0">
      <div
        id="header-carousel"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-bs-interval="20000"
      >
        <div className="carousel-inner">
          {/* Slide 1 */}
          <div
            className={`carousel-item position-relative ${
              carouselIndex === 0 ? "active" : ""
            }`}
            style={{ height: "100vh" }}
          >
            <img
              className="position-absolute w-100 h-100"
              src={slider1}
              alt="Gender Inclusion"
              style={{
                objectFit: "cover",
                objectPosition: "top",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 0,
              }}
            ></div>
            <div
              className="carousel-caption d-flex flex-column align-items-center justify-content-center text-center"
              style={{
                zIndex: 2,
                minHeight: "100vh",
              }}
            >
              <div style={{ maxWidth: "1080px", padding: "1rem" }}>
                <h4 className="display-5 fw-semibold text-white my-4 pt-5">
                  <ReactTypingEffect
                    text={[
                      "Leading Gender and Disability Mainstreaming in Kenya and Africa",
                    ]}
                    speed={150}
                    eraseSpeed={50}
                    typingDelay={200}
                    eraseDelay={3500}
                  />
                </h4>
                <h3 className="lead mb-4 text-white">
                  At GDDC Kenya, we are committed to championing the inclusion
                  of gender and disability perspectives in all sectors of
                  society. Our work focuses on empowering marginalized groups
                  and transforming institutional frameworks to foster an
                  inclusive and equitable future for all. Together, we can
                  create an environment where everyone, regardless of gender or
                  ability, has the opportunity to thrive.
                </h3>
                <a
                  className="btn btn-secondary text-light"
                  href="/about"
                  style={{
                    fontSize: "1.1rem",
                    padding: "12px 25px",
                  }}
                >
                  Learn More About Our Mission
                </a>
              </div>
            </div>
          </div>

          {/* Slide 2 */}
          <div
            className={`carousel-item position-relative ${
              carouselIndex === 1 ? "active" : ""
            }`}
            style={{ height: "100vh" }}
          >
            <img
              className="position-absolute w-100 h-100"
              src={slider2}
              alt="Training Officers"
              style={{
                objectFit: "cover",
                objectPosition: "top",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 0,
              }}
            ></div>
            <div
              className="carousel-caption d-flex flex-column align-items-center justify-content-center text-center"
              style={{
                zIndex: 2,
                height: "100%",
              }}
            >
              <div style={{ maxWidth: "1080px", padding: "1rem" }}>
                <h4
                  className="display-5 fw-semibold text-white my-4 pt-5 pt-md-3"
                  style={{ letterSpacing: "3px" }}
                >
                  <ReactTypingEffect
                    text={[
                      "150,000+ Officers Trained in Gender and Disability Mainstreaming",
                    ]}
                    speed={150}
                    eraseSpeed={50}
                    typingDelay={200}
                    eraseDelay={3500}
                  />
                </h4>
                <h3 className="lead mb-4 text-white">
                  Our dedicated training programs have successfully equipped
                  over 150,000 public and private sector officers with the
                  knowledge and skills needed to integrate gender and disability
                  considerations into their work. Through specialized workshops
                  and tailored support, we help organizations develop more
                  inclusive policies and practices, ensuring long-term impact
                  for individuals and communities alike.
                </h3>
                <a
                  className="btn btn-secondary text-light"
                  href="/services/training-and-workshops"
                  style={{
                    fontSize: "1.1rem",
                    padding: "12px 25px",
                  }}
                >
                  See Our Impact
                </a>
              </div>
            </div>
          </div>

          {/* Slide 3 */}
          <div
            className={`carousel-item position-relative ${
              carouselIndex === 2 ? "active" : ""
            }`}
            style={{ height: "100vh" }}
          >
            <img
              className="position-absolute w-100 h-100"
              src={slider3}
              alt="Workshop Registration"
              style={{
                objectFit: "cover",
                objectPosition: "top",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 0,
              }}
            ></div>
            <div
              className="carousel-caption d-flex flex-column align-items-center justify-content-center text-center"
              style={{
                zIndex: 2,
                height: "100%",
              }}
            >
              <div style={{ maxWidth: "1080px", padding: "1rem" }}>
                <h4
                  className="display-5 fw-semibold text-white my-4 pt-5 pt-md-3"
                  style={{ letterSpacing: "3px" }}
                >
                  <ReactTypingEffect
                    text={[
                      "Join Our Next Gender and Disability Mainstreaming Workshop",
                    ]}
                    speed={150}
                    eraseSpeed={50}
                    typingDelay={200}
                    eraseDelay={3500}
                  />
                </h4>
                <h3 className="lead mb-4 text-white">
                  Ready to make a difference? Our upcoming workshop offers a
                  unique opportunity to learn from experienced trainers,
                  exchange ideas with fellow advocates, and deepen your
                  understanding of gender and disability mainstreaming. Whether
                  you're part of an organization or a community leader, this is
                  your chance to be part of a transformative journey that drives
                  meaningful change in society.
                </h3>
                <a
                  className="btn btn-secondary text-light"
                  href="/events"
                  style={{
                    fontSize: "1.1rem",
                    padding: "12px 25px",
                  }}
                >
                  Register for Upcoming Events
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Carousel Controls */}
        <a
          className="carousel-control-prev"
          href="#header-carousel"
          role="button"
          data-bs-slide="prev"
        >
          <div className="btn btn-lg btn-secondary btn-lg-square">
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
          </div>
        </a>
        <a
          className="carousel-control-next"
          href="#header-carousel"
          role="button"
          data-bs-slide="next"
        >
          <div className="btn btn-lg btn-secondary btn-lg-square">
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default HeroCarousel;
