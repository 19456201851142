import CallToAction from "../components/CallToAction";
import HeroCarousel from "../components/HeroCarousel";
import Navbar from "../components/Navbar";
import OurImpact from "../components/OurImpact";
import OurJourney from "../components/OurJourney";
import Services from "../components/Services";
import WhyChooseUs from "../components/WhyChooseUs";
import Footer from "../components/Footer";
import PartnersClients from "../components/PartnersClients";

export default function Home() {
    return (
      <>
        <Navbar />
        <HeroCarousel />
        <OurJourney />
        <OurImpact />
        <Services />
        <WhyChooseUs />
        {/* <PartnersClients /> */}
        <CallToAction />
        <Footer />
      </>
    );
}