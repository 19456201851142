import React from "react";

const Sponsorship = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4 card-title">The Sponsorship</h2>

      <section className="mb-4">
        <p>
          The 2024 Kenya’s Diversity and Inclusion Awards ceremony aims to
          recognize the success and vitality of the Country in ensuring that
          there are equal opportunities to all, empowering both men and women of
          Kenya, and enabling access to wealth creation opportunities without
          discrimination. This unique annual awards program has been established
          specifically to acknowledge, encourage, and celebrate excellence
          amongst Kenyan institutions and individuals by giving them the
          opportunity to gain invaluable exposure and well-deserved recognition
          for their national and global equality and inclusion interventions.
        </p>
      </section>

      <section className="mb-4">
        <p>
          The Awards are the highest honors that can be bestowed to a Ministry,
          Government Agency, State Corporation, The Corporate, and Business
          Owners/Leaders in the Country. The main goal of the Award program is
          therefore, to appreciate key players in the social sector who have
          created a level playing field for the vulnerable in society to grow
          and prosper, and to award them, market them, their products, and
          services in the Country and beyond.
        </p>
      </section>

      <section className="mb-4">
        <p>
          The organizers of this important initiative greatly acknowledge and
          salute your organization’s support and contribution to promoting
          diversity, equity, and equality in the workplace and to society. We
          would be honored if you could consider sponsoring the event. The event
          attracts service providers, investors, entrepreneurs, and high-profile
          speakers and participants from Government, private sector
          institutions, small and medium enterprise support agencies, and other
          development institutions throughout the country.
        </p>
      </section>
    </div>
  );
};

export default Sponsorship;
