import { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "./images/GDDC LOGO.png";

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 60) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTogglerClick = () => {
    setNavOpen(!navOpen);
  };

  let navbarClasses = scrolled
    ? "navbar fixed-top navbar-expand-lg px-md-5 bg-light shadow"
    : "navbar navbar-expand-lg px-md-5 bg-light shadow";

  return (
    <>
      <div className="container-fluid bg-danger text-white text-center py-3 w-100 d-block">
        <Link
          to="/national-equality-and-inclusion-awards"
          className="text-white"
        >
          The 2024 National Equality and Inclusion Awards to be held on 20th Dec 2024
        </Link>
      </div>
      <nav className={navbarClasses}>
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="logo" height="100px" className="rounded" />
          </Link>
          <button
            className={`navbar-toggler ${navOpen ? "open" : ""}`}
            type="button"
            onClick={handleTogglerClick}
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={navOpen ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`collapse navbar-collapse ${navOpen ? "bg-light" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto">
              <NavigationLink name="Home" link="/" />
              <NavigationLink name="About" link="/about" />
              <ServicesDropdown />
              <NavigationLink name="Events" link="/events" />
              <NavigationLink name="Membership" link="/membership" />
              <NavigationLink name="Awards" link="/awards" />
              <NavigationLink name="Resources" link="/resources" />
              <NavigationLink name="Contact" link="/contact" />
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

function NavigationLink(props) {
  return (
    <li className="nav-item">
      <NavLink
        to={props.link}
        className={({ isActive }) =>
          `nav-link me-4 text-uppercase fw-bold ${isActive ? "active" : ""}`
        }
      >
        {props.name}
      </NavLink>
    </li>
  );
}

function ServicesDropdown() {
  const [isHovered, setIsHovered] = useState(false);

  const dropdownMenuStyles = {
    display: isHovered ? "block" : "none",
    position: "absolute",
    top: "100%",
    left: "0",
    zIndex: "1000",
    minWidth: "10rem",
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <li
      className="nav-item dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      <a
        href="/services"
        className="nav-link dropdown-toggle me-4 text-uppercase fw-bold d-flex align-items-center"
        id="servicesDropdown"
        role="button"
        aria-expanded={isHovered ? "true" : "false"}
      >
        Services
      </a>
      <ul
        className="dropdown-menu"
        aria-labelledby="servicesDropdown"
        style={dropdownMenuStyles}
      >
        <li>
          <Link
            to="/services/research-and-publications"
            className="dropdown-item"
          >
            Research and Publications
          </Link>
        </li>
        <li>
          <Link to="/services/training-and-workshops" className="dropdown-item">
            Trainings/Workshop
          </Link>
        </li>
        <li>
          <Link to="/services/certification" className="dropdown-item">
            Certification
          </Link>
        </li>
        <li>
          <Link to="/services/policy-development" className="dropdown-item">
            Policy Development
          </Link>
        </li>
        <li>
          <Link to="/services/consultancy" className="dropdown-item">
            Consultancy
          </Link>
        </li>
      </ul>
    </li>
  );
}
