import React from "react";

const AwardsParticipants = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Awards Participants</h2>

      <section className="card mb-4">
        <h3 className="text-secondary card-title">
          National Government Ministries, Departments, and Agencies (MDAs)
        </h3>
        <p className="card-text">
          All state corporations, universities, TVETs, and research institutes,
          including autonomous and semi-autonomous bodies, are invited to the
          awards. Institutions are encouraged to attend with leadership and
          gender/disability committee members, with ten-member table
          reservations available for each institution.
        </p>
      </section>

      <section className="card mb-4">
        <h3 className="text-secondary card-title">County Governments</h3>
        <p className="card-text">
          County participation aligns with the National Programme of Action
          (NPoA) commitments made by the president under APRM. This includes
          peer review mechanisms and initiatives to advance good governance and
          the implementation of key national and international goals like SDGs
          and Africa Agenda 2063. Eight counties are piloting APRM: Garissa,
          Kisumu, Kakamega, Baringo, Taita Taveta, Kitui, Nyandarua, and Kisii.
        </p>
      </section>

      <section className="card mb-4">
        <h3 className="text-secondary card-title">
          Private Sector, NGOs, and Organizations for Persons with Disabilities
        </h3>
        <p>
          Recognizing the private sector's role in the GDP, the awards encourage
          private organizations to go beyond charity by including Persons with
          Disabilities in decision-making and workplace inclusion. The awards
          invite private institutions to network, benchmark, and collaborate
          with stakeholders like the National Council for Persons with
          Disabilities, Gender and Equality Commission, and Disabled Peoples
          Organizations.
        </p>
      </section>
    </div>
  );
};

export default AwardsParticipants;
