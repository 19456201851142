import Navbar from "../components/Navbar";
import OurImpact from "../components/OurImpact";
import CoreValues from "../components/CoreValues";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import ConsultancyContent from "../components/Consultancy"
import PartnersClients from "../components/PartnersClients";

function Consultancy() {
    return (
        <>
            <Navbar />
            <ConsultancyContent />
            <OurImpact />
            <CoreValues />
            {/* <PartnersClients /> */}
            <CallToAction />
            <Footer />
        </>
    );
}

export default Consultancy;