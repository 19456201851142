import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/bootstrap.css";
import "./js/bootstrap.bundle.min.js";
import "./js/script.js";
import "./css/styles.css";
import "./css/font-awesome.css";
import DynamicTitle from "./components/DynamicTitle.js";
import ScrollToTop from "./components/ScrollToTop.js";
import CookieConsent from "./components/CookieConsent.js";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import ServicesOverview from "./components/ServiceOverview.js";
import ResearchPublications from "./pages/ResearchPublications.js";
import TrainingWorkshop from "./pages/TrainingWorkshop.js";
import Certification from "./pages/Certification.js";
import PolicyDevelopment from "./pages/PolicyDevelopment.js";
import Consultancy from "./pages/Consultancy.js";
import Contact from "./pages/Contact.js";
import Events from "./pages/Events.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import TermsConditions from "./pages/TermsConditions.js";
import PageNotFound from "./pages/404.js";
import Resources from "./pages/Resources.js";
import Awards from "./pages/Awards.js";
import Membership from "./pages/Membership.js";
import LoadingScreen from "./components/LoadingScreen.js";
import NationalEqualityInclusionAwards from "./components/NationalEqualityInclusionAwards.js";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.userway.org/widget.js";
    script.setAttribute("data-account", "MvZkYuHdWy");
    script.async = true;
    document.head.appendChild(script);

    const handleLoad = () => setIsLoading(false);

    const fallbackTimer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    window.addEventListener("load", handleLoad);

    return () => {
      document.head.removeChild(script);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Router>
      <DynamicTitle />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/services/research-and-publications"
          element={<ResearchPublications />}
        />
        <Route
          path="/services/training-and-workshops"
          element={<TrainingWorkshop />}
        />
        <Route path="/services/certification" element={<Certification />} />
        <Route
          path="/services/policy-development"
          element={<PolicyDevelopment />}
        />
        <Route path="/services/consultancy" element={<Consultancy />} />
        <Route path="/services" element={<ServicesOverview />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/membership" element={<Membership />} />
        <Route
          path="/national-equality-and-inclusion-awards"
          element={<NationalEqualityInclusionAwards />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <CookieConsent />
    </Router>
  );
};

const root = ReactDOM.createRoot(document.querySelector("#root"));
root.render(<App />);
