import CallToAction from "../components/CallToAction";
import CoreValues from "../components/CoreValues";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import OurImpact from "../components/OurImpact";
import PartnersClients from "../components/PartnersClients";
import ProfileSummary from "../components/ProfileSummary";
import VisionMandate from "../components/VisionMandate";

export default function About(){
    return (
        <>
        <Navbar />
        <ProfileSummary />
        <VisionMandate />
        <OurImpact />
        <CoreValues />
        {/* <PartnersClients /> */}
        <CallToAction />
        <Footer />
        </>
    );
}