import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PartnersClients from "../components/PartnersClients";
import ResourcesContent from "../components/ResourcesContent";

export default function Resources() {
    return (
        <>
            <Navbar />
            <ResourcesContent />
            {/* <PartnersClients /> */}
            <Footer />
        </>
    );
}