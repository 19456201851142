import "./css/Services.css";
import ExecutiveDirector from "./images/Executive Director Richard  S Nasong'o and H.E  Governor James Ongwae at the 2015 Mainstreaming Awards Gala.jpg";
import second from "./images/gerald receives_award for county.jpg";
import third from "./images/H.E Dep. Governor Gerald Githinji Issuing and Award to Sony Sugar MD Jane Pamella Odhiambo and other staff members.jpg";
import fourth from "./images/H.E Hon ongwae during the 2015 awards.jpg";
import fifth from "./images/PS Josephta Mukobe Principal Secretary State Department of Special Programmes Issues Award trophy and Certificate to H.E Gerald Githinji Deputy Governor Kiambu County and Esther Ndirangu CEC Gender.jpg";
import sixth from "./images/Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia.jpg";

export default function AwardsContent() {
  return (
    <div className="specificService">
      <div className="container pt-5">
        <h2 className="text-center my-4">
          National Gender and Disability Mainstreaming Excellency Awards
        </h2>
        <p>Our awards celebrate organizations excelling in:</p>
        <ul>
          <li>Diversity in Leadership and Governance</li>
          <li>Service Excellence</li>
          <li>Inclusive and Accessible Workplace Environments</li>
        </ul>
        <p>
          This year, outstanding organizations will receive awards for their
          commitment to diversity and inclusion, including government
          institutions, private organizations, and NGOs.
        </p>

        <p>
          The awards ceremony will be held on <strong>December 20, 2024</strong>{" "}
          at Kenyatta International Convention Centre (KICC), with various
          organizations represented.
        </p>

        <p>
          The Diversity in Governance Awards honor institutions that demonstrate
          exemplary practices in governance by fostering racially, ethnically,
          and gender-diverse leadership. Successful recipients are recognized
          for:
        </p>
        <ul>
          <li>Implementing comprehensive diversity policies</li>
          <li>Reaching out to diverse communities</li>
          <li>Tracking and reporting on diversity goals</li>
        </ul>

        <p>
          Our media partners champion the awards, promoting awareness and
          encouraging participation from government and private institutions.
          Their role helps ensure the spotlight is on those driving gender and
          disability mainstreaming across Kenya, acknowledging organizations and
          individuals who have pioneered initiatives to enhance inclusivity.
        </p>

        <div
          id="awardsCarousel"
          className="carousel slide mt-5 pb-5"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#awardsCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#awardsCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#awardsCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#awardsCarousel"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#awardsCarousel"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#awardsCarousel"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
          </div>

          <div className="carousel-inner">
            {[
              {
                src: ExecutiveDirector,
                alt: "Award 1",
                caption:
                  "Executive Director Richard S Nasong'o and H.E Governor James Ongwae at the 2015 Mainstreaming Awards Gala",
              },
              {
                src: second,
                alt: "Award 2",
                caption: "Gerald receives award for county",
              },
              {
                src: third,
                alt: "Award 3",
                caption:
                  "H.E Dep. Governor Gerald Githinji Issuing an Award to Sony Sugar MD Jane Pamella Odhiambo and other staff members",
              },
              {
                src: fourth,
                alt: "Award 4",
                caption: "H.E Hon Ongwae during the 2015 awards",
              },
              {
                src: fifth,
                alt: "Award 5",
                caption:
                  "PS Josephta Mukobe Principal Secretary State Department of Special Programmes Issues Award trophy and Certificate to H.E Gerald Githinji Deputy Governor Kiambu County and Esther Ndirangu CEC Gender",
              },
              {
                src: sixth,
                alt: "Award 6",
                caption:
                  "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
              },
            ].map((item, index) => (
              <div
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
              >
                <div style={{ width: "100%", height: "500px" }}>
                  {" "}
                  <img
                    src={item.src}
                    className="d-block w-100 h-100 rounded"
                    alt={item.alt}
                  />
                </div>
                <div className="carousel-caption-bottom">
                  <p>{item.caption}</p>
                </div>
              </div>
            ))}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#awardsCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#awardsCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}
