import Navbar from "../components/Navbar";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import EventsContent from "../components/EventsContent";
import EventsDescription from "../components/EventsDescription";
import PartnersClients from "../components/PartnersClients";

function Events() {
    return (
        <>
            <Navbar />
            <EventsContent />
            <EventsDescription />
            {/* <PartnersClients /> */}
            <CallToAction />
            <Footer />
        </>
    );
}

export default Events;