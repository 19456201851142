import React from "react";

const SponsorCategories = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4 card-title">Sponsor Categories</h2>

      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-secondary">
                Awards Headline Sponsorship - KSh. 5,000,000 (x1)
              </h3>
              <p>
                Our headline partners have an advantageous opportunity to become
                an integral part of the event from pre-event promotion, opening
                address, and highlighting your organization’s work in diversity,
                equality, and inclusion with brand placement opportunities. In
                addition, your branding will be applied to our awards brochure,
                communications, and a mix of items that will be present at the
                event.
              </p>
              <h5>Full Package Details:</h5>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i> An opening speech slot at the
                  awards ceremony.
                </li>
                <li>
                  <i className="fa fa-check"></i> Company branding on an award
                  category of your choice with one of your teams to present to
                  the winning organization at the awards ceremony.
                </li>
                <li>
                  <i className="fa fa-check"></i> Inclusion of your marketing
                  materials or branded items in the event literature and
                  giveaways.
                </li>
                <li>
                  <i className="fa fa-check"></i> Corporate branding on all
                  invitations, our website, and awards brochure, located in
                  print and digital format throughout the event venue, with some
                  bespoke opportunities to be agreed upon.
                </li>
                <li>
                  <i className="fa fa-check"></i> A double full-page
                  feature/advertisement in the awards brochure.
                </li>
                <li>
                  <i className="fa fa-check"></i> Placement in all pre &
                  post-event communications, press releases, and selected social
                  media posts.
                </li>
                <li>
                  <i className="fa fa-check"></i> Networking opportunities at
                  the awards ceremony.
                </li>
                <li>
                  <i className="fa fa-check"></i> Personal consultation with a
                  KENEI D&I Lead for any projects or requirements (3 hours).
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-secondary">
                Individual Awards Sponsorship (Package Prestige) - KSh.
                1,000,000 (x10)
              </h3>
              <p>
                As a key partner, you will have the opportunity to support any
                one or more of our incredible awards categories. By sponsoring a
                specific award or set of awards, partners can highlight their
                commitment to particular award areas by recognizing the
                nominees’ achievements and presenting the awards to deserving
                winners.
              </p>
              <h5>Full Package Details:</h5>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i> Placement in all pre &
                  post-event communications, press releases, and selected social
                  media.
                </li>
                <li>
                  <i className="fa fa-check"></i> The opportunity to present
                  your chosen award category(s).
                </li>
                <li>
                  <i className="fa fa-check"></i> Networking opportunities at
                  the awards ceremony.
                </li>
                <li>
                  <i className="fa fa-check"></i> Corporate branding on
                  invitations, our website, and selected social media posts.
                </li>
                <li>
                  <i className="fa fa-check"></i> Table allocation to ten staff
                  members.
                </li>
                <li>
                  <i className="fa fa-check"></i> A full page in the Equality
                  and Inclusion Souvenir Magazine.
                </li>
                <li>
                  <i className="fa fa-check"></i> A two-hour FREE sensitization
                  to your organization on gender or disability mainstreaming
                  prior to the award.
                </li>
                <li>
                  <i className="fa fa-check"></i> Free Baseline survey for your
                  organization prior to the awards.
                </li>
                <li>
                  <i className="fa fa-check"></i> Media highlights in
                  recognition as a partner champion for the mainstreaming of
                  gender and equality.
                </li>
                <li>
                  <i className="fa fa-check"></i> Free mounting of your
                  corporate banner.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-secondary">Venue Sponsor (x1)</h3>
              <p>
                We are looking for a venue sponsor to provide a space to host
                our annual Equality and Inclusion Excellence Awards 2024. The
                venue will need to be able to host upto 750 people for our awards
                evening, with a stage, space for tables, and a networking area.
                We would like to work with our venue sponsor to ensure that the
                event is accessible to all our attendees. In return, you’ll have
                the opportunity to showcase your space to a wide audience
                through the in-person event, social media, and our awards
                brochure.
              </p>
              <h5>Full Package Details:</h5>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i> Speech slot at the awards
                  ceremony.
                </li>
                <li>
                  <i className="fa fa-check"></i> Tickets to the awards ceremony
                  for up to 10 attendees (in addition to staff required for the
                  staffing of the venue).
                </li>
                <li>
                  <i className="fa fa-check"></i> Placement in all pre &
                  post-event communications, press releases, and selected social
                  media.
                </li>
                <li>
                  <i className="fa fa-check"></i> The opportunity to present
                  your chosen award category(s).
                </li>
                <li>
                  <i className="fa fa-check"></i> Networking opportunities at
                  the awards ceremony.
                </li>
                <li>
                  <i className="fa fa-check"></i> Corporate branding on
                  invitations and our website.
                </li>
                <li>
                  <i className="fa fa-check"></i> A full-page advertisement in
                  the awards brochure.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-secondary">
                Gold Sponsor - KSh. 475,000
              </h3>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i> Table allocation to five of
                  your staff members.
                </li>
                <li>
                  <i className="fa fa-check"></i> A half-page in the Equality
                  and Inclusion Awards Souvenir Magazine.
                </li>
                <li>
                  <i className="fa fa-check"></i> Free Baseline survey/Basic
                  Accessibility Audit for your organization prior to the awards.
                </li>
                <li>
                  <i className="fa fa-check"></i> Title allotted trophy with
                  your organization name to one of the winners and presented by
                  your organization’s senior staff representative.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-secondary">
                Silver Sponsor - KSh. 300,000
              </h3>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check"></i> Table allocation to three of
                  your staff members.
                </li>
                <li>
                  <i className="fa fa-check"></i> A half-page in the Equality
                  and Inclusion Awards Souvenir Magazine.
                </li>
                <li>
                  <i className="fa fa-check"></i> Title allotted trophy with
                  your organization name to one of the winners and presented by
                  your organization’s senior staff representative.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorCategories;
